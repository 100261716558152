import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBar from "../../NotificationBar";
import { useGA } from "../../../hooks";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

var HeaderNotificationBar = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useGA = useGA(),
      trackClick = _useGA.trackClick;

  var _useLocalStorage = useLocalStorage('v6-header-notification-bar-visited-1120', false),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      isVisited = _useLocalStorage2[0],
      setIsVisited = _useLocalStorage2[1];

  var url = 'https://v5.tokenlon.im';

  var handleLinkClick = () => {
    trackClick('webtl_topbanner', {
      title: t('header_notification'),
      url
    });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  var handleClose = () => {
    setIsVisited(true);
  };

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
  }, []);
  return __jsx(React.Fragment, null, !isVisited && __jsx(NotificationBar, {
    text: t('header_notification'),
    linkText: t('header_notification_btn'),
    handleClose: handleClose,
    handleLinkClick: handleLinkClick,
    style: {
      position: 'sticky',
      top: 0,
      zIndex: 200,
      borderRadius: 0
    }
  }));
};

export default HeaderNotificationBar;