import React from "react";
var __jsx = React.createElement;
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconArrowGray from "../../../assets/images/arrow-gray.svg";
import { useBreakpoint, useGA } from "../../../hooks";
import { LOCALES } from "../../../locales";
import MobileLinkItem from "./MobileLinkItem";
import { StyledFooterListArea } from "./styled";
import { useModalToggle } from "../../../state/application/hooks";
import { ApplicationModal } from "../../../state/application/reducer";
import { useHelpScout } from "../../../hooks/useHelpScout";
import { ASSET_PREFIX } from "../../../constants/env";

var useFooterData = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var locale = i18n.resolvedLanguage;
  var isZh = i18n.resolvedLanguage === LOCALES.ZH;
  var en = !isZh ? 'en' : '';
  var voteUrl = isZh ? 'https://tokenlon.zendesk.com/hc/zh-cn/articles/360051220292' : 'https://tokenlon.zendesk.com/hc/en-us/articles/360051220292-How-to-use-Snapshot-to-participate-in-Tokenlon-pilot-community-governance';
  var zendeskLan = isZh ? 'zh-cn' : 'en-us';
  var targetLitepaper = "".concat(ASSET_PREFIX, "/files/").concat(isZh ? 'Tokenlon-Litepaper_zh-cn' : 'Tokenlon-litepaper_en-us', ".pdf");

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var data = (isBreakPointLG ? [{
    title: t('language'),
    items: [{
      url: "".concat(LOCALES.ZH),
      name: '简体中文'
    }, {
      url: "".concat(LOCALES.EN),
      name: 'English'
    }],
    showDivider: true
  }] : []).concat([{
    title: t('discover'),
    items: [{
      url: targetLitepaper,
      name: t('litepaper')
    }, {
      url: "https://github.com/consenlabs/tokenlon-contracts/tree/master/audits",
      name: t('audit_report')
    }, {
      url: 'https://tokenlon.im/brand',
      name: t('brand_and_logo')
    }],
    showDivider: false
  }, {
    title: t('developer_document'),
    showDivider: false,
    items: [{
      url: 'https://github.com/consenlabs/tokenlon-contracts',
      name: t('smart_contract')
    }, {
      url: 'https://tokenlon-v5-sdk.docs.imvercel.works/',
      name: t('sdk')
    }, {
      url: "https://docs.token.im/tokenlon-onboarding/".concat(en),
      name: t('market_maker_document')
    }, {
      url: "https://docs.token.im/tokenlon-open-api?locale=".concat(locale),
      name: t('open_api')
    }]
  }, !isBreakPointLG ? {
    title: t('social'),
    showDivider: false,
    items: [{
      url: 'https://discord.gg/ZFf4Cye',
      name: 'Discord'
    }, {
      url: 'https://forum.tokenlon.im/',
      name: t('gov_forum')
    }, {
      url: 'https://snapshot.org/#/tokenlon.eth',
      name: t('snapshot')
    }]
  } : undefined, {
    title: t('footer_support'),
    showDivider: false,
    items: [{
      url: "https://support.tokenlon.im/hc/".concat(zendeskLan),
      name: t('help_center')
    }, {
      url: "https://support.tokenlon.im/hc/".concat(zendeskLan, "/articles/360047156012"),
      name: t('tokenlon_tutorial')
    }, {
      url: "https://support.tokenlon.im/hc/".concat(zendeskLan, "/articles/360038363811"),
      name: t('support_token')
    }, {
      url: "https://tokenlon.gitbook.io/docs/".concat(!isZh ? 'v/docs.en/' : 'v/docs.cn/'),
      name: t('wiki')
    }, {
      url: 'mailto:bd@tokenlon.im',
      name: t('business')
    }]
  }]);

  if (isBreakPointLG) {
    data.unshift({
      title: t('products'),
      items: [{
        url: '/instant',
        name: t('instant_swap')
      }, {
        url: '/limit-order',
        name: t('limit_order')
      }, {
        url: '/weth',
        name: t('wrap_eth')
      }]
    }, {
      title: 'LON',
      items: [{
        url: '/lon',
        name: t('sub_nav_overview')
      }, {
        url: '/lon/dashboard',
        name: t('sub_nav_dashboard')
      }, {
        url: '/lon/mining/trade',
        name: t('sub_nav_trade_mining')
      }, {
        url: '/lon/buyback',
        name: t('sub_nav_buyback')
      }, {
        url: '/lon/staking',
        name: t('sub_nav_staking')
      }, {
        url: '/lon/mining/lp',
        name: t('sub_nav_liquity_mining')
      }]
    }, {
      title: t('bridges_menu'),
      items: [{
        url: 'https://bit.ly/3xiuP8L',
        name: 'Hop'
      }, {
        url: 'https://bridge.arbitrum.io/',
        name: 'Arbitrum'
      }, {
        url: 'https://bit.ly/3wIClv5',
        name: 'Celer cBridge'
      }]
    }, {
      title: t('community'),
      items: [{
        url: 'https://forum.tokenlon.im/c/tokenlon-bounty/9',
        name: t('bounty')
      }, {
        url: 'https://forum.tokenlon.im/',
        name: t('forum')
      }, {
        url: 'https://snapshot.org/#/tokenlon.eth',
        name: t('vote')
      }, {
        url: 'https://twitter.com/tokenlon',
        name: t('twitter')
      }, {
        url: 'https://discord.com/invite/ZFf4Cye',
        name: t('discord')
      }]
    }, {
      title: 'Blog',
      url: '/blog',
      showDivider: true
    });
    data.push({
      title: t('help_and_feedback'),
      showDivider: false,
      items: [{
        url: '',
        name: ''
      }]
    });
  }

  return data.filter(d => !!d);
};

export var FooterListArea = _ref => {
  var className = _ref.className;
  var data = useFooterData();

  var _useState = useState(-1),
      openedListIndex = _useState[0],
      setOpenedListIndex = _useState[1];

  var _useBreakpoint2 = useBreakpoint(),
      isBreakPointLG = _useBreakpoint2.isBreakPointLG;

  var _useGA = useGA(),
      trackClick = _useGA.trackClick;

  var _useRouter = useRouter(),
      push = _useRouter.push;

  var toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true
  });
  var toggleHelpScout = useHelpScout();

  var handleListOpen = index => {
    var isLastItem = index === data.length - 1;

    if (isLastItem && isBreakPointLG) {
      toggleHelpScout();
    }

    if (index === openedListIndex) {
      if (index !== -1) {
        setOpenedListIndex(-1);
      }
    } else {
      setOpenedListIndex(index);
    }
  };

  var onMenuClick = url => {
    if (url) {
      push(url);
      toggleSidebar();
    }
  };

  var onTrack = obj => {
    trackClick('webtl_bottom_detail', obj);
  };

  return __jsx(StyledFooterListArea, {
    className: className
  }, data.map((list, index) => {
    var title = list.title,
        _list$items = list.items,
        items = _list$items === void 0 ? [] : _list$items,
        showDivider = list.showDivider,
        url = list.url;
    return __jsx("div", {
      className: "".concat(openedListIndex === index && 'active'),
      key: index,
      onClick: () => onMenuClick(url)
    }, __jsx("h3", {
      onClick: () => handleListOpen(index)
    }, title, isBreakPointLG && items.length > 0 && __jsx("img", {
      className: "arrow-icon",
      src: IconArrowGray
    }), isBreakPointLG && !items.length && __jsx("img", {
      className: "arrow-icon arrow-icon__rotate",
      src: IconArrowGray
    })), __jsx("ul", null, items.map((item, itemIndex) => __jsx(MobileLinkItem, {
      onClick: () => onTrack({
        type: title,
        title: item.name,
        url: item.url
      }),
      url: item.url,
      name: item.name,
      key: itemIndex
    }))), showDivider && __jsx("hr", {
      className: "list-divider"
    }));
  }));
};
export default FooterListArea;