import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
var __jsx = React.createElement;
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from "../../../hooks";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { LOCALES } from "../../../locales";
import styled from 'styled-components';
var StyledLanguageArea = styled.div.withConfig({
  displayName: "StyledLanguageArea",
  componentId: "sc-shamtg-0"
})(["position:relative;margin:0 25px;background:transparent;", ""], _ref => {
  var theme = _ref.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    margin: 0 12px 0 0;\n  "])));
});
var StyledLanguageAreaSelector = styled.div.withConfig({
  displayName: "StyledLanguageAreaSelector",
  componentId: "sc-shamtg-1"
})(["display:flex;align-items:center;cursor:pointer;.current-language{font-size:14px;line-height:20px;color:#333333;margin-bottom:0;transition:color 0.3s;}.trangle{width:0;height:0;border-style:solid;border-width:5px 4.5px 2px 4.5px;border-color:#333333 transparent transparent transparent;transition:border-color 0.3s;margin-left:3px;margin-top:2px;}"]);
var StyledLanguagePicker = styled.div.withConfig({
  displayName: "StyledLanguagePicker",
  componentId: "sc-shamtg-2"
})(["position:absolute;top:32px;left:-20px;z-index:1000;font-size:15px;line-height:26px;background:#fff;border-radius:4px;box-shadow:0 50px 100px rgba(50,50,93,0.1),0 15px 35px rgba(50,50,93,0.15),0 5px 15px rgba(0,0,0,0.1);transform:rotate3d(1,1,0,15deg);display:none;transform-origin:0 100%;will-change:transform,display;transition-property:transform,display,-webkit-transform;transition-duration:0.25s;&.visible{display:block;}&:before{content:'';position:absolute;top:-6px;left:20px;width:20px;height:20px;transform:rotate(-135deg);border-radius:20px 0 3px 0;background-color:#fff;}"]);
var StyledOptionList = styled.div.withConfig({
  displayName: "StyledOptionList",
  componentId: "sc-shamtg-3"
})(["color:#525f7f;white-space:nowrap;margin:0;list-style:none;padding-left:0;padding:10px 5px;a{cursor:pointer;color:#5f4dbc;border-radius:4px;display:block;line-height:36px;margin:0;padding:0 30px;text-decoration:none;&:hover{background-color:#f6f9fc;}}&.active{transform:none;opacity:1;pointer-events:auto;padding:10px 5px;}"]);

var Languages = () => {
  var ref = useRef();

  var _useState = useState(false),
      visible = _useState[0],
      setVisible = _useState[1];

  var _useLocalStorage = useLocalStorage('lang', LOCALES.EN),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      _ = _useLocalStorage2[0],
      setValue = _useLocalStorage2[1];

  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;

  var clickHandler = (event, lang) => {
    event.preventDefault();
    setValue(lang);
    i18n.changeLanguage(lang);
    setVisible(false);
  };

  useClickAway(ref, () => setVisible(false));
  return __jsx(StyledLanguageArea, {
    ref: ref
  }, __jsx(StyledLanguageAreaSelector, {
    onClick: () => setVisible(last => !last)
  }, __jsx("p", {
    id: "current-language",
    className: "current-language"
  }, isZh ? '简体中文' : 'En'), __jsx("div", {
    className: "trangle"
  })), __jsx(StyledLanguagePicker, {
    className: visible ? 'visible' : ''
  }, __jsx(StyledOptionList, null, __jsx("li", null, __jsx("a", {
    "data-language": "zh-CN",
    onClick: e => clickHandler(e, LOCALES.ZH),
    className: isZh ? 'selected' : ''
  }, __jsx("span", null, "\u7B80\u4F53\u4E2D\u6587"))), __jsx("li", null, __jsx("a", {
    "data-language": "en-US",
    onClick: e => clickHandler(e, LOCALES.EN),
    className: !isZh ? 'selected' : ''
  }, __jsx("span", null, "English"))))));
};

export default Languages;